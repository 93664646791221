import React from 'react';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className={`modal ${this.props.isActive ? 'is-active' : ''}`}>
                <div className='modal-background'></div>
                <div style={{ backgroundColor: 'black', padding: '5px' }} className='modal-card'>
                    <div className='modal-card-head'>
                        <p className={'modal-card-title is-marginless has-text-centered'}>
                            Contact
                        </p>
                        <button className='delete' onClick={e => {
                            e.preventDefault();
                            this.props.close(e);
                        }} />
                    </div>
                    <div className="section-light contact" id="contact">
                        <form
                            action="https://formsubmit.co/ewager@ewager.online"
                            method="POST"
                            encType="multipart/form-data">
                            <input type="hidden" name="_next" value="https://stream.ewager.online" />
                            <input type="hidden" name="_captcha" value="false" />
                            <div className="field">
                                <label className="label is-default-txt">Name</label>
                                <div className="control has-icons-left">
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="Ex. Jane Smith"
                                        name="name" />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label is-default-txt">Email</label>
                                <div className="control has-icons-left">
                                    <input
                                        className="input"
                                        type="name"
                                        placeholder="Ex. hello@arctheme.com"
                                        name="email" />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label is-default-txt">Message</label>
                                <div className="control">
                                    <textarea className="textarea"
                                        placeholder="Textarea"
                                        name="message" />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button className="button is-full-width" type="submit" value="Send">
                                        Submit&nbsp;&nbsp;
									  <i className="fas fa-paper-plane"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}