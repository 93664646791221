import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Header from './layout/Header';
import { MessageSection, TwitchViewer, Contact, CountDown } from './components';
import { openStream } from './service';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const isTest = false;
const testData = [
	{
		stream: "ninja",
		game: {
			number: 15,
			type: "Fortnite",
			time: "14:05",
			// (waiting, started, finished)
			state: "started",
			// (pending, win, loss, undetermined)
			condition: "pending"
		},
		escrows: [{ publicKey: 'THIS WOULD BE A STELLAR PUBLIC ADDRESS' }],
		// confidence score
		winPct: "40%",
		lossPct: "60%",
		undeterminedPct: "0%"
	},
	{
		stream: "imaqtpie",
		game: {
			number: 10,
			type: "League of Legends",
			time: "12:05",
			state: "started",
			condition: "pending"
		},
		escrows: [{ publicKey: 'THIS WOULD BE A STELLAR PUBLIC ADDRESS' }],
		// confidence score
		winPct: "40%",
		lossPct: "55%",
		undeterminedPct: "5%"
	},
	{
		stream: "rotterdam08",
		game: {
			number: 6,
			type: "Starcraft II",
			time: "20:05",
			state: "started",
			condition: "pending"
		},
		escrows: [{ publicKey: 'THIS WOULD BE A STELLAR PUBLIC ADDRESS' }],
		// confidence score
		winPct: "30%",
		lossPct: "65%",
		undeterminedPct: "5%"
	},
	{
		stream: "rotterdam08",
		game: {
			number: 6,
			type: "Starcraft II",
			time: "20:05",
			state: "started",
			condition: "pending"
		},
		escrows: [{ publicKey: 'THIS WOULD BE A STELLAR PUBLIC ADDRESS' }],
		// confidence score
		winPct: "30%",
		lossPct: "65%",
		undeterminedPct: "5%"
	}
];

export default class Home extends React.Component {
	constructor() {
		super();
		this.state = {
			isInfo: false,
			isContact: false,
			isLoggedIn: true,
			isModal: false,
			server: "wss.ewager.online",
			port: "8080",
			connected: false,
			isSsl: true,
			messages: '',
			isMainTab: true,
			isTwitchTab: false,
			twitchScreens: null,
			isLoading: true
		}
	}

	componentDidMount() {
		this.openWss();
	}

	test = () => {
		this.renderTwitchScreens(testData);
	}

	openWs = () => {
		openStream(this.state.server + ":" + this.state.port, this.handleResponse, () => {
			this.setState({ connected: false });
		}, () => {
			this.setState({ connected: true });
		}, () => {
			this.setState({ connected: false });
		}, this.state.isSsl);
	}

	openWss = () => {
		if (this.state.connected) {
			toast.dark('You Are Connected.', {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
		openStream(this.state.server, this.handleResponse, () => {
			this.setState({ connected: false });
		}, () => {
			this.setState({ connected: true });
		}, () => {
			this.setState({ connected: false });
		}, this.state.isSsl);
	}

	handleResponse = (response) => {
		if (response === null || response === undefined || response.data === null
			|| response.data === undefined) {
			return;
		}
		// console.log("RESPONSE")
		// console.log(response)
		let data;
		try {
			data = JSON.parse(response.data);
		} catch (e) {
			return;
		}
		if (data.length === 0) {
			return;
		}
		if (this.state.isLoading) {
			this.setState({ isLoading: false });
		}
		this.renderTwitchScreens(data);
	};

	isConnectedDisplay = () => {
		const icon = this.state.connected ? "fas fa-check-square" : "fas fa-ban";
		const color = this.state.connected ? "icon has-text-success" : "icon has-text-danger";
		return (<span className={color}>
			<i key={icon}>
				<span className={icon} />
			</i>
		</span>);
	}

	isSslDisplay = () => {
		const buttonType = this.state.isSsl ? "button is-success" : "button is-danger";
		const buttonText = this.state.isSsl ? "SSL On" : "SSL Off";
		return (<div className="control">
			<button className={buttonType}
				onClick={() => { this.setState({ isSsl: !this.state.isSsl }) }}>{buttonText}</button>
		</div>);
	}

	openConnectionView = () => {
		return (<div className="field is-grouped is-grouped-multiline">
			{this.isConnectedDisplay()}
			<div className="control">
				<button className="button is-success"
					onClick={() => isTest ? this.openWs() : this.openWss()}>Open Connection</button>
			</div>
		</div>)
	}

	testView = () => {
		return (<div style={{ marginTop: '20px' }}>
			<div className="field is-grouped is-grouped-multiline">
				<div className="field">
					<label className="label is-default-txt">Server Address:</label>
					<div className="control">
						<input className="input"
							onChange={(text) => this.setState({ server: text.target.value })}
							type="text"
							placeholder="ex: localhost"
						/>
					</div>
				</div>
				<div className="field" style={{ marginLeft: '10px' }}>
					<label className="label is-default-txt">Port:</label>
					<div className="control">
						<input className="input"
							onChange={(text) => this.setState({ port: text.target.value })}
							value={this.state.port}
							type="text"
							placeholder="ex: 9191"
						/>
					</div>
				</div>
				{this.openConnectionView()}
				<div style={{ marginLeft: '10px' }} />
				{this.isSslDisplay()}
				<div className="control">
					<button className="button is-warning"
						onClick={() => this.test()}>Test Interface</button>
				</div>
			</div>
		</div>);
	}

	twitchViewer = (channel) => {
		let game = channel.game;
		return (
			<TwitchViewer
				key={channel.stream}
				stream={channel.stream}
				type={game.type}
				number={game.number}
				time={game.time}
				condition={game.condition}
				state={game.state}
				winPct={channel.winPct}
				lossPct={channel.lossPct}
				undeterminedPct={channel.undeterminedPct}
				escrows={channel.escrows}
			/>
		)
	}

	modal = () => {
		return (<div className="modal is-active">
			<div className="modal-background"></div>
			<div className="modal-card">
				<header className="modal-card-head">
					<p className="modal-card-title">Kill Stream Option</p>
					<button className="delete" aria-label="close"
						onClick={() => { this.setState({ isModal: false }) }}></button>
				</header>
				<section className="modal-card-body">
					<input className="input"
						onChange={(text) => this.setState({ streamToKill: text.target.value })}
						type="text"
						placeholder="Enter Steam To Kill Here"
					/>
				</section>
				<footer className="modal-card-foot">
					<button className="button is-warning"
						onClick={() => {
							alert("STUFF")
							this.setState({ isModal: false });
						}}>Kill</button>
				</footer>
			</div>
		</div>);
	}

	renderTwitchScreens = (data) => {
		// console.log(data)
		if (data === null || data === undefined) {
			return;
		}

		const RSIZE = 3;
		let columns = [];
		let views = [];
		for (let i = 0; i < data.length; i++) {
			if (!data[i]) {
				return;
			}
			columns.push(
				<div className="column is-one-third" key={i}>
					{this.twitchViewer(data[i])}
				</div>
			);
			if (columns.length === RSIZE || i === data.length - 1) {
				views.push(<div className="columns is-vcentered" key={i}>
					{columns}
				</div>)
				columns = [];
			}
		}
		this.setState({ twitchScreens: views });
	}

	renderInfoSection = () => {
		return (
			<div>
				<section class="hero is-primary is-small is-bold">
					{/* <div class="hero-head"> </div> */}
					<div class="hero-body">
						<div class="container">
							{/* has-text-centered */}
							<h1 class="title">
								Information (BETA VERSION 0.2.0)
                    		</h1>
							<h2 class="subtitle">
								What Is Currently Supported:
								<li className='is-margin-left-li'>Stellar Currency</li>
								<li className='is-margin-left-li'>Multiple Twitch Games (more to come)</li>
							</h2>
							<h2 class="subtitle">
								How To Bet:<br />
								<li className='is-margin-left-li'>You must have a stellar account</li>
								<li className='is-margin-left-li'>Betting is currently supported on the win loss of the next game</li>
								<li className='is-margin-left-li'>To place a bet click on the bet button below a game and follow the instructions</li>
							</h2>
							<h2 class="subtitle">
								Notes:<br />
								<li className='is-margin-left-li'>You're stellar account wallet must be an address that can recieve the winnings</li>
								<li className='is-margin-left-li'>Winnings are paid out after the game ends</li>
								<li className='is-margin-left-li'>If a game goes undetermined, the money will be returned (currently)</li>
							</h2>
							<h2 class="subtitle">
								Future:<br />
								<li className='is-margin-left-li'>More currency support</li>
								<li className='is-margin-left-li'>Changes based on feedback</li>
							</h2>
							<h2 class="subtitle has-text-centered">Please contact below if you are a Twitch streamer who wants to be on here.</h2>
						</div>
					</div>
				</section>
			</div>);
	}

	render() {
		return (
			<div>
				<Header
					isInfo={this.state.isInfo}
					onInfoClick={() => { this.setState({ isInfo: !this.state.isInfo }) }}
					isConnected={this.state.connected}
					onConnectClick={() => this.openWss()}
				/>
				{this.state.isInfo && this.renderInfoSection()}
				<section className="section">
					<ToastContainer />
					<MessageSection
						header={'Ewager Twitch Platform'}
					// headerRight={this.openConnectionView()}
					>
					</MessageSection>
					<div className='is-margin-top is-full-centered'>
						{this.state.isLoading && <CountDown />}
						<Loader
							type="Circles"
							color="#008080"
							height={100}
							width={100}
							timeout={0}
							visible={this.state.isLoading}
						/>
					</div>
					{this.state.isModal && this.modal()}
					{this.state.isLoggedIn && <div className="container">
						{this.state.isMainTab && isTest && this.testView()}
						<div style={{ marginTop: "30px" }}>
							{this.state.twitchScreens}
						</div>
						<div style={{ marginTop: "10px" }}>
							<div className='subtitle is-default-txt'>If you would like to participate and join our list above,
							please contact us and submit a request.</div>
							<button className="button is-primary"
								style={{ width: "100%" }}
								onClick={() => { this.setState({ isContact: !this.state.isContact }) }}>
								Contact Us
							</button>
							<Contact isActive={this.state.isContact}
								close={() => { this.setState({ isContact: !this.state.isContact }) }}
							/>
						</div>
					</div>}
				</section>
			</div>
		)
	}

}