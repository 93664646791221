import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './../Home'
import Settings from './../Settings'
const Router = () => (
	<Switch>
		<Route exact path='/' component={Home} />
		<Route path='/settings' component={Settings} />
	</Switch>
)
export default Router