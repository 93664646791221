import React from 'react';

export default class Settings extends React.Component {

	constructor() {
		super();
		this.state = {

		}
	}

	componentDidMount() {

	}

	render() {
		return (
			<section className="section">
				COMING SOON
			</section>
		)
	}
}