/*** WEBSOCKET CONTROL ***/
let ws = null;
export const openStream = async (address, callback, onerror, onopen, onclose, isSsl = true) => {
	if (ws === null) {
		const socketType = isSsl ? 'wss' : 'ws';
		ws = new WebSocket(`${socketType}://${address}/clientSocket`);
	}
	ws.onerror = () => {
		console.log('WebSocket error');
		onerror();
	};
	ws.onopen = () => {
		console.log('WebSocket connection established');
		onopen();
	};
	ws.onmessage = (message) => callback(message);
	ws.onclose = () => {
		ws = null;
		console.log('WebSocket connection closed');
		onclose();
	};
}