import React from 'react';
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader-spinner'

export default class MessageSection extends React.Component {

	render() {
		return (<div>
			<div className="field is-grouped is-grouped-multiline">
				<div className='field container is-grouped is-grouped-multiline'>
					<h1 className="title  is-default-txt">{this.props.header}</h1>
					{this.props.headerRight}
				</div>
			</div>
			{
				this.props.isLoading && <div className="level-item has-text-centered">
					<Loader
						type="Triangle"
						color='#008080'
						height="100"
						width="100"
					/></div>
			} <div>
				{/* <ToastContainer /> */}
			</div>
		</div>);
	};
}