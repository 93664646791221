import React from 'react';
import ReactPlayer from 'react-player';
import BetContainer from './BetContainer';

export default class TwitchViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }


    getTwitchStreamUrl = (stream) => {
        return "https://www.twitch.tv/" + stream;
    }

    render() {
        let { stream, type, number, time, condition, state, winPct, lossPct, undeterminedPct } = this.props;
        return (
            <div className="field">
                <ReactPlayer width="100%" height="400px" style={{ backgroundColor: "black" }}
                    url={this.getTwitchStreamUrl(stream)} playing={true} muted={true} />
                <div className="control">
                    <div className="columns is-gapless is-multiline is-mobile">
                        {/* row 1 */}
                        <div className="column is-half is-default-txt">
                            <b>Stream:</b> {stream}
                        </div>
                        <div className="column is-half has-text-right is-default-txt">
                            <b>Game:</b> {type}
                        </div>
                        {/* row 2 */}
                        <div className="column is-half is-default-txt">
                            <b>Number:</b> {number}
                        </div>
                        <div className="column is-half has-text-right is-default-txt">
                            <b>Time:</b> {time}
                        </div>
                        {/* row 3 */}
                        <div className="column is-half is-default-txt">
                            <b>Condition:</b> {condition}
                        </div>
                        <div className="column is-half has-text-right is-default-txt">
                            <b>State:</b> {state}
                        </div>
                        {/* row 4 */}
                        <div className="column is-half is-default-txt">
                            <b>Win:</b> {winPct}
                        </div>
                        <div className="column is-half has-text-right is-default-txt">
                            <b>Loss:</b> {lossPct}
                        </div>
                        {/* row 5 */}
                        <div className="column is-half is-default-txt">
                            <b>Undetermined:</b> {undeterminedPct}
                        </div>
                        <div className="column is-half has-text-right is-default-txt">
                            <b></b>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <BetContainer escrows={this.props.escrows} />
                    </div>
                    {/* <nav className="level">
                        <p className="level-item has-text-centered">
                            <button className="button is-success"
                                onClick={() => { alert("TODO DISPAY MODAL WITH ADDRESS") }}>
                                Bet Win
						</button>
                        </p>
                        <p className="level-item has-text-centered">
                            <button className="button is-danger"
                                onClick={() => { alert("TODO DISPAY MODAL WITH ADDRESS") }}>
                                Bet Lose
						</button>
                        </p>
                    </nav> */}
                </div>
            </div>
        )
    }
}