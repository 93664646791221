import React from 'react';

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
			isDropdownActive: false
		}
	}

	toggleNav = () => {
		this.setState(prevState => ({
			isActive: !prevState.isActive
		}));
	}

	toggleDropdown = () => {
		this.setState(prevState => ({
			isDropdownActive: !prevState.isDropdownActive
		}));
	}

	renderTabs = () => {
		return (
			<div className={this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
				<div className="navbar-end">
					{/* INFO */}
					<div class="navbar-item" key={`INFO_KEY_${this.props.isInfo}`} >
						<p className="control">
							<a className={this.props.isInfo ? "button is-primary is-inverted"
								: "button is-primary is-inverted is-outlined"}
								onClick={() => this.props.onInfoClick()} >
								<span className="icon">
									<i className='fas fa-info' />
								</span>
								<span>{'Info'}</span>
							</a>
						</p>
					</div>
					{/* CONNECTION STATUS */}
					<div class="navbar-item" key={`CONNECT_KEY_${this.props.isConnected}`}>
						<p className="control">
							<a className="button is-primary is-inverted is-outlined" disabled={this.props.isConnected} onClick={() => this.props.onConnectClick()} >
								<span className="icon">
									<i className={this.props.isConnected ? 'fas fa-plug' : 'fas fa-unlink'} />
								</span>
								<span>{this.props.isConnected ? 'Connected' : 'Connect'}</span>
							</a>
						</p>
					</div>
				</div>
			</div>);
	}

	renderConnected = () => {
		return (
			<div class="navbar-end" key={`CONNECT_KEY_${this.props.isConnected}`}>
				<div class="navbar-item">
					<p className="control is-full-centered">
						<a className="button is-primary is-inverted is-outlined" onClick={() => this.props.onConnectClick()} >
							<span className="icon">
								<i className={this.props.isConnected ? 'fas fa-plug' : 'fas fa-unlink'} />
							</span>
							<span>Connected</span>
						</a>
					</p>
				</div>
			</div>
		);
	}

	render() {
		return (
			<nav className="navbar" role="navigation" aria-label="main navigation">
				<div className='container'>
					<div className="navbar-brand">
						<a className="navbar-item" href="/" >
							<img src={require('../../images/ewager-white-clear-cropped.svg')} />
						</a>
						<button className={this.state.isActive ? 'nav-hamburger button navbar-burger is-active' : 'nav-hamburger button navbar-burger'}
							onClick={this.toggleNav} data-target="navbarContent">
							<span style={{ background: "white" }}></span>
							<span style={{ background: "white" }}></span>
							<span style={{ background: "white" }}></span>
						</button>
					</div>
					{this.renderTabs()}
					{/* {this.renderConnected()} */}
				</div>
			</nav>
		)
	}
}