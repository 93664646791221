import React from 'react'

const Footer = () => (
	<footer className="footer"
		style={{
			color: "white",
			alignContent: "center",
			alignItems: "center",
			alignSelf: "center",
			background: "#242424"
		}}>
		<div className="container">
			<div className="content has-text-centered" >
				{/* <a className="is-primary-txt" href="/privacy"> Privacy Policy </a> |
				<a className="is-primary-txt" href="/terms"> Terms Of Service</a> */}
				<p> Powered By <strong className="is-primary-txt">ewager.online</strong> <br />
					Copyright {new Date().getFullYear()} <br />
				</p>
			</div>
		</div>
	</footer>
)

export default Footer