import React from 'react';
import { CopyToClipboard as Clipboard } from 'react-copy-to-clipboard';

const BetModal = ({ publicKey, isActive, onModalClose }) => (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
        <div className='modal-background'></div>
        <div className='modal-card'>
            <div className='modal-card-head'>
                <p className={'modal-card-title ' +
                    'is-marginless has-text-centered'}>
                    Place a New Bet
                </p>
                <button className='delete' onClick={e => {
                    e.preventDefault();
                    onModalClose(e);
                }} />
            </div>
            <section className={'modal-card-body ' +
                'has-text-centered'}>
                <h5 className='title is-5 has-margin-bottom-1'>
                    Your Bet
          </h5>
                <p>
                    To place this bet, send stellar to the address below.
                    <br />
                    IMPORTANT: memo must be WIN or LOSS.
                    <br />
                    If this team wins the game, the bet will payout to
                    the account that the payment was sent from.
                </p>
                <div className='field'>
                    <div className='control'>
                        <input className='input'
                            type='text' readOnly='readonly'
                            value={publicKey} />
                    </div>
                </div>
                <div className='buttons is-centered'>
                    <Clipboard text={publicKey}>
                        <button className='button is-primary'>
                            Copy Address
              </button>
                    </Clipboard>
                </div>
            </section>
        </div>
    </div>
);

export default class BetContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            publicKey: null
        };
        this.onBetClick =
            this.onBetClick.bind(this);
        this.onModalClose =
            this.onModalClose.bind(this);
    }

    componentDidMount() {
        const publicKey = this.props.escrows ? this.props.escrows.length > 0 ? this.props.escrows[0].publicKey : '' : '';
        this.setState({
            publicKey
        });
    }

    onBetClick() {
        this.setState({
            modal: true
        });
    }

    onModalClose() {
        this.setState({
            modal: false
        });
    }

    render() {
        return (
            <div>
                <button className='button is-primary'
                    onClick={() => this.onBetClick()}
                    style={{ width: '100%' }}>
                    Bet
                </button>
                <BetModal
                    publicKey={this.state.publicKey}
                    isActive={this.state.modal}
                    onModalClose={
                        this.onModalClose
                    } />
            </div>
        );
    }
}